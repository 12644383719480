import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import emailjs from 'emailjs-com'
import emailjs, { init } from 'emailjs-com'

init('' + process.env.REACT_APP_USER_ID) // alternative to passing userId into send method

const Confirmation = () => {
    return(
        <span className='confirm'>
            Great to hear from you!
        </span>
    )
}

const Inquiries = () => {
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [reply_to, setreply_to] = useState('')
    const [message, setmessage] = useState('')
    const [sent, setsent] = useState(false)

    // ref -  https://www.webtips.dev/react-contact-form-without-backend
    const isValidEmail = reply_to => {
        // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(reply_to).toLowerCase());
    } 

    const submit = (e) => {
        e.preventDefault()
        if( firstName && lastName && reply_to && message ){
            if(isValidEmail){
                const serviceId = process.env.REACT_APP_SERVICE_ID
                const templateId = process.env.REACT_APP_TEMPLATE_ID
                const userId = process.env.REACT_APP_USER_ID
                const templateParams = {
                    firstName,
                    lastName,
                    reply_to,
                    message
                }

                emailjs.send(serviceId, templateId, templateParams, userId)
                    .then(response => console.log(response))
                    .then(error => console.log(error))

                setfirstName('')
                setlastName('')
                setreply_to('')
                setmessage('')
                setsent(true)
            } else {
                alert('Please enter a valid email address.')
            }
        } else {
            alert('Please fill in all fields.')
        }
    }

    return (
        <div className="contactForm">
            <h1> 🖃 <br/> Send some mail! </h1>
            <h5> 
            It's no postcard, but it's officially <br/> the quickest way to contact me. 
            </h5>

            <input type='text' placeholder='First Name' value={firstName} onChange={ e => setfirstName(e.target.value)} />
            <input type='text' placeholder='Last Name' value={lastName} onChange={ e => setlastName(e.target.value)} />
            <br/>
            <input type='email' placeholder='Email Address' value={reply_to} onChange={ e => setreply_to(e.target.value)} />
            <br/>
            <textarea placeholder='Message' value={message} onChange={ e => setmessage(e.target.value)} />
            <br/>
            <Link to='/'>
                <button className="App-link"> ⬅ Return </button>
            </Link>
            <button className='App-link' onClick={submit}> Submit ➡ </button>
            <br/>
            {/* <span className={sent ? 'visible' : null}>Good to hear from you!</span> */}
            { sent 
                ? <Confirmation /> 
                : null 
            }
        </div>
    )
}

export default Inquiries
