import './App.css'

import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Port from './Components/Port/Port'
import Inquiries from './Components/Inquiries/Inquiries'
import Resume from './Components/Resume/Resume'

// import profile_photo from './profile.jpg'
import sq_profile_photo from './square_profile.jpg'
import ds_profile_photo from './downscaled_square_profile.jpg'

function App() {
  return (
    <div className="App">
      <main>
        <div className="FlexColumn g2">
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/about' exact component={About} />
            <Route path='/more' exact render={() => <Redirect to='/about'/>} />
            <Route path='/portfolio' exact component={Port} />
            <Route path='/inquiries' exact component={Inquiries} />
            <Route path='/resume' exact component={Resume} />
            <Route path='/' render={() => <Redirect to='/'/>} />
          </Switch>
        </div>
        
        <div className="FlexColumn g1">
          <picture>
            <source srcSet={sq_profile_photo} className='App-profile-photo' media='(min-width: 1920px)' />
            <source srcSet={sq_profile_photo} className='App-profile-photo' media='(min-width: 1200px)' />
            <source srcSet={sq_profile_photo} className='App-profile-photo' media='(min-width: 1024px)' />
            <source srcSet={ds_profile_photo} className='App-profile-photo' media='(min-width: 600px)'/>
            <img src={ds_profile_photo} className='App-profile-photo' alt='profile' />
          </picture>
        </div>
      </main>
    </div>
  )
}

export default App