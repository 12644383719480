import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../viz.png'
import linkedin from '../../linkedin-3-16.ico'
import insta from '../../instagram-3-16.ico'

const Home = () => {
    return (
        <div className="App-Home-detail_panel">
          <Link to='/'>
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          
          <h1 className="Name"> Trevor Foren </h1>
          <span></span>
          <h2> Software Developer <br /> ++grind ++hustle </h2>
          <p> Cranking out code every week, <br/>
              climbing every weekend.
              {/* <br/> Aspiring to live my life 100Km at a time. */}
          </p>

          {/* todo - style buttons to stack vertically on mobile devices */}
          <div>
            <Link to='/about'>
              <button className="App-link"> More on me </button>
            </Link>

            <Link to='/portfolio'>
              <button className="App-link"> Portfolio </button>
            </Link>

            <Link to='/inquiries'>
              <button className="App-link"> Inquiries </button>
            </Link>
          </div>


          {/* social icons */}
          <div className='socials'>
            <a
              className="App-link"
              href="https://www.linkedin.com/in/trevorforen/"
              target="_blank"
              rel="noopener noreferrer"
            > 
              <img src={linkedin} alt='linked in' />
            </a>

            <a
              className="App-link"
              href="https://www.instagram.com/trevor4n/"
              target="_blank"
              rel="noopener noreferrer"
            > 
              <img src={insta} alt='instagram' />
            </a> 
          </div>
        </div>
    )
}

export default Home