import React from 'react'
import { Link } from 'react-router-dom'
import Project from '../Project/Project'
import img_tile_sutton from '../../Sutton-Screenshot_Easy-Resize.com.jpg'
import img_tile_eah from '../../desktop-snippet-index-route.jpg'
import img_tile_hostless from '../../Hostless-App_Deployment.jpg'
import './style.css'

const Port = () => {
    const initialProjects = [
        {
            name : "Sutton",
            description: "a fruity memory game!",
            imgTile: img_tile_sutton,
            techUsed: ['HTML', 'CSS', 'JS', 'Fetch API', 'Unsplash API'],
            github: 'https://github.com/trevor4n/Sutton',
            liveDemo: 'https://trevor4n.github.io/Sutton/'
        },
        {
            name: 'Environmental Advocacy Hub',
            description: 'bite-sized environmental data',
            imgTile: img_tile_eah,
            techUsed: ['Express', 'Node', 'Mongoose', 'EJS', 'Materialize', 'PassportJS'],
            github: 'https://github.com/trevor4n/ENV-Advocacy/tree/main',
            liveDemo: 'https://infinite-savannah-66084.herokuapp.com/'
        },
        {
            name: 'Hostless',
            description: 'abstract reservation management system',
            imgTile: img_tile_hostless,
            techUsed: ['Mongoose', 'Express', 'React', 'Node', 'Axios'],
            github: 'https://github.com/HostlessApp',
            liveDemo: 'https://hostlessapp.github.io/Hostless-App/'
        }
    ]

    return (
        <div className='App-Port-content'>
            {/* <Link to='/'>
                <img src={logo} className="App-logo" alt="logo" />
            </Link> */}
            <h1> Portfolio Projects</h1>
            {/* actively working to showcase three projects */}
            { initialProjects.map( project => (
                <Project key={project.name} project={project} />
            )) }

            {/* todo */}
            {/* each project should show growth over time */}
            {/* format: high level overview w/ brief intro and further exploration linked */}
            {/* artifacts: outlines, sketches, doodles, prototypes, wireframes, lists, post-its */}
            {/* case studies: outlining the story from start to finish */}
            
            <Link to='/'>
                <button className="App-link"> ⬅ Return </button>
            </Link>
            {/* todo - bottom padding for responsive, galaxy, moto devices */}
            <br/><br/> 
        </div>
    )
}

export default Port