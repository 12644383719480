import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import logo from '../../viz.png'

const Resume = () => {
    return (
        <div className="App-resume_cont">
            <Link to='/'>
                <img src={logo} className="App-logo" alt="logo" />
            </Link>

            <br/>

            <Link to='/about'>
                <button className="App-link"> ⬅ Return </button>
            </Link>

            <br/> <br/>
            <div className="iframe-container">
                <iframe class="responsive-iframe" src="https://drive.google.com/file/d/10cCMsjWnjPnXkoWoGHsQSJl_AqHXUmNl/preview" width="640" height="480" allow="autoplay" title="Trevor's Resume"></iframe>
            </div>
            
            {/* <Link to='/about'>
                <button className="App-link"> ⬅ Return </button>
            </Link> */}
        </div>
    )
}

export default Resume
