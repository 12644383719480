import React from 'react'
import github from '../../GitHub-Mark-Light-32px.png'
import './style.css'

const Project = (props) => {
    let techStack = props.project.techUsed.join(', ')

    return (
        <div className='App-Project-card'>
            <div className="App-Project-card_cover" 
                style={{
                    'background-image': `url(${props.project.imgTile})`
                    }}
            >
            </div>

            <div className="App-Project-card_content">
                <span className='App-Project-name'> 🉑 { props.project.name } </span>
                <span className='App-Project-concept'> Concept: { props.project.description } </span>
                <span className='App-Project-stack'> Tech Stack: { techStack } </span>
                <a
                    className="App-link"
                    href={props.project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                > 
                    <img src={github} alt='github' />
                </a>
                {/* todo - live demo link */}
            </div>
            
        </div>
    )
}

export default Project
