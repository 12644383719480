import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../viz.png'
import './style.css'

const About = () => {
    return (
        <div className="App-About-content">
            <Link to='/'>
                <img src={logo} className="App-logo" alt="logo" />
            </Link>

            <h1>Oh hey there!</h1>
            <h6> If you're looking for a fresh full-stack developer, <br/> you've come to the right place. </h6>
            <p> 
                Just before lockdown I worked for a horticultural conservatory. <br/> 
                I loved the balance - based in the city of Milwaukee and surrounded by nature. <br/> 
                As businesses shut down, I was reminded just how essential our environment truly is. <br/>
                <br/>

                Despite its downsides, covid has been a major career catalyst for me. <br/>
                It's also why I'm so keen on pursuing a remote career!
            </p>
            <p className="hl">
                Lockdown provided an opportunity to explore my options. 
                I enrolled in General Assembly's Software Engineering Immersive program, wrapping up September 2021. {/* <br/>  */} I also have a 3 year engineering internship under my belt with Dynmamic Ratings!
            </p>
            <p> 
                {/* I've spent the last 6 months really diving into development! <br/> */}
                Best of all, I'm looking for a full time position, <br/> so don't hesitate to get in contact with me!
            </p>
            <Link to='/'>
                <button className="App-link"> ⬅ Return </button>
            </Link>
            <Link to='/resume'>
                <button className="App-link"> 📄 Resume </button>
            </Link>
            {/* todo - bottom padding for responsive, galaxy, moto devices */}
            {/* <br/><br/>  */}
        </div>
    )
}

export default About
